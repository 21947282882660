<template>
  <v-sheet>
    <div ref="col" class="col col-1" style="display: none"></div>
    <div class="d-flex mb-2 align-center">
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
        Hôm nay
      </v-btn>
      <v-btn
        fab
        text
        small
        color="grey darken-2"
        @click="$refs.calendar.prev()"
      >
        <v-icon small> mdi-chevron-left </v-icon>
      </v-btn>
      <v-btn
        fab
        text
        small
        color="grey darken-2"
        @click="$refs.calendar.next()"
      >
        <v-icon small> mdi-chevron-right </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
            <span>{{ typeToLabel[type] }}</span>
            <v-icon right> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="type = 'day'">
            <v-list-item-title>Ngày</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'week'">
            <v-list-item-title>Tuần</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'month'">
            <v-list-item-title>Tháng</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-sheet ref="sheetCalendar">
      <v-calendar
        ref="calendar"
        event-overlap-mode="column"
        v-model="focus"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
      ></v-calendar>
    </v-sheet>
    <!-- Begin detail event -->
    <DialogEventDetail
      v-model="isOpenDetail"
      :eventId="selectedEventId"
    ></DialogEventDetail>
    <!-- End event detail -->
  </v-sheet>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _get from "lodash/get";
import _map from "lodash/map";
import moment from "moment";

import DialogEventDetail from "./DialogEventDetail.vue";

export default {
  components: {
    DialogEventDetail,
  },
  data: () => ({
    isOpenDetail: false,
    selectedEventId: 0,
    value: "",
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Tháng",
      week: "Tuần",
      day: "Ngày",
    },
    mode: "stack",
    weekday: [1, 2, 3, 4, 5, 6, 0],
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
  }),
  computed: {
    ...mapState("event", {
      listEvent: (state) => state.events,
    }),
  },
  methods: {
    ...mapActions("event", ["list"]),
    setupLayout() {
      const PADDING = 12;

      let refSheetCalendar = this.$refs.sheetCalendar;

      let top = refSheetCalendar.$el.getBoundingClientRect().top;
      let height = window.innerHeight - top - PADDING * 2;

      refSheetCalendar.$el.style.height = `${height}px`;
    },
    getEventColor(event) {
      return event.color;
    },
    computedEvent() {
      let events = _map(this.listEvent, (item) => {
        let startDate = _get(item, "startDate");
        let startTime = _get(item, "startTime");
        let momentStart = moment(
          `${startDate} ${startTime}`,
          "YYYY-MM-DD hh:ii:ss"
        );

        let endDate = _get(item, "endDate");
        let endTime = _get(item, "endTime");
        let momentEnd = moment(`${endDate} ${endTime}`, "YYYY-MM-DD hh:ii:ss");

        let event = {
          id: _get(item, "id"),
          color: "blue",
          start: momentStart.toDate(),
          end: momentEnd.toDate(),
          name: _get(item, "name"),
          timed: startTime != "00:00:00" || endTime != "23:59:59",
        };
        return event;
      });

      this.events = events;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    showEvent({ nativeEvent, event }) {
      let id = _get(event, "id");
      this.selectedEventId = id;

      this.isOpenDetail = true;

      nativeEvent.stopPropagation();
    },
  },
  watch: {
    listEvent() {
      this.computedEvent();
    },
  },
  mounted() {
    this.setupLayout();
  },
};
</script>
