<template>
  <div class="pa-3">
    <v-row>
      <v-col cols="3">
        <v-btn block color="primary" @click="openEventCreate = true">
          Tạo sự kiện
        </v-btn>
        <div class="mt-3">
          <v-divider class="mb-3"></v-divider>
          <FilterEvent></FilterEvent>
        </div>
        <div class="mt-3">
          <v-divider class="mb-3"></v-divider>
          <EventInDay></EventInDay>
        </div>
      </v-col>
      <v-col cols="9">
        <Calendar></Calendar>
      </v-col>
    </v-row>
    <DialogEventCreate v-model="openEventCreate"></DialogEventCreate>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Calendar from "./Components/Calendar.vue";
import FilterEvent from "./Components/FilterEvent.vue";
import EventInDay from "./Components/EventInDay.vue";
import DialogEventCreate from "./Components/DialogEventCreate.vue";

export default {
  components: {
    FilterEvent,
    EventInDay,
    Calendar,
    DialogEventCreate,
  },
  methods: {
    ...mapActions("event", ["list"]),
  },
  data: () => ({
    openEventCreate: false,
  }),
  async mounted() {
    await this.list();
  },
};
</script>
