<template>
  <v-dialog
    v-model="isOpen"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card color="grey lighten-3">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Tạo mới sự kiện</v-toolbar-title>
      </v-toolbar>
      <v-container class="d-flex flex-column justify-center align-center">
        <EventDetail
          class="mt-4"
          style="max-width: 60rem; width: 100%"
        ></EventDetail>

        <div v-if="!isEmpty(event)" class="step-line"></div>
        <v-tooltip v-if="!isEmpty(event)" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              v-bind="attrs"
              v-on="on"
              @click="() => addAction()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Thêm hành động</span>
        </v-tooltip>

        <div
          v-for="(action, index) in actions"
          :key="`action-${index}`"
          class="d-flex flex-column justify-center align-center"
          style="max-width: 60rem; width: 100%"
        >
          <div class="step-line"></div>
          <div class="step-line-end">▼</div>

          <ActionDetail
            style="max-width: 60rem; width: 100%"
            @remove="() => removeAction(index)"
          ></ActionDetail>

          <div class="step-line"></div>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="() => addAction()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Thêm hành động</span>
          </v-tooltip>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.step-line {
  background-color: rgb(153, 153, 153);
  height: 16px;
  width: 2px;
  z-index: 0;
}
.step-line-end {
  color: rgb(153, 153, 153);
  z-index: 4;
  line-height: 1;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import _isEmpty from "lodash/isEmpty";

import EventDetail from "./EventDetail.vue";
import ActionDetail from "./ActionDetail.vue";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    EventDetail,
    ActionDetail,
  },
  data: () => ({
    isOpen: false,
    eventExpand: false,
    actions: [],
  }),
  computed: {
    ...mapState("event", {
      event: (state) => state.event,
    }),
  },
  watch: {
    value(value) {
      this.isOpen = value;
    },
    isOpen(value) {
      this.$emit("input", value);

      if (!value) {
        this.resetEvent();
      }
    },
  },
  methods: {
    ...mapActions("event", ["resetEvent"]),
    isEmpty(item) {
      return _isEmpty(item);
    },
    addAction() {
      this.actions = [...this.actions, 0];
    },
    removeAction(index) {
      this.actions.splice(index, 1);
    },
  },
  mounted() {
    this.isOpen = this.value;
  },
};
</script>
