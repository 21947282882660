<template>
  <v-card>
    <v-row>
      <v-col cols="1">
        <v-btn fab outlined color="primary" class="ml-4 mt-4">
          <v-icon size="32">mdi-information</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="9">
        <v-card-title class="font-weight-bold">
          {{ name || "Tên sự kiện" }}
        </v-card-title>
        <v-card-subtitle>
          {{ get(eventStatus, status) || "Đang soạn" }}
        </v-card-subtitle>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn
          v-if="isEmpty(event)"
          fab
          small
          elevation="0"
          class="mr-4 mt-6"
          color="primary"
          :loading="isLoading"
          @click="create"
        >
          <v-icon> mdi-content-save </v-icon>
        </v-btn>
        <v-btn
          v-else
          fab
          small
          elevation="0"
          class="mr-4 mt-6"
          color="primary"
          :loading="isLoading"
          @click="isOpenUpdateDialog = true"
        >
          <v-icon> mdi-content-save </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text v-if="expand">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          outlined
          label="Name"
          v-model="name"
          :disabled="isLoading"
          :rules="rules.name"
        ></v-text-field>
        <v-select
          outlined
          label="Trạng thái"
          item-text="text"
          item-value="value"
          v-model="status"
          :items="statusSelectItems"
          :rules="rules.status"
          :disabled="isLoading"
        ></v-select>
        <v-row>
          <v-col cols="6" class="py-0 pt-3">
            <v-menu
              ref="startDateMenu"
              transition="scale-transition"
              offset-y
              min-width="auto"
              v-model="menuStartDate"
              :close-on-content-click="false"
              :disabled="isLoading"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Từ ngày"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  v-model="startDate"
                  :rules="rules.startDate"
                  :disabled="isLoading"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="menuStartDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="py-0 pt-3">
            <v-menu
              ref="startTimeMenu"
              offset-y
              max-width="290px"
              min-width="290px"
              transition="scale-transition"
              :close-on-content-click="false"
              :return-value.sync="startTime"
              :disabled="isLoading"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Từ lúc"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-model="startTime"
                  :rules="rules.startTime"
                  :disabled="isLoading"
                ></v-text-field>
              </template>
              <v-time-picker
                full-width
                format="24hr"
                v-model="startTime"
                @click:minute="$refs.startTimeMenu.save(startTime)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="py-0 pb-3">
            <v-menu
              ref="endDateMenu"
              transition="scale-transition"
              offset-y
              min-width="auto"
              v-model="menuEndDate"
              :close-on-content-click="false"
              :disabled="isLoading"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Đến ngày"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  v-model="endDate"
                  :rules="rules.endDate"
                  :disabled="isLoading"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="menuEndDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" class="py-0 pb-3">
            <v-menu
              ref="endTimeMenu"
              offset-y
              max-width="290px"
              min-width="290px"
              transition="scale-transition"
              :close-on-content-click="false"
              :return-value.sync="endTime"
              :disabled="isLoading"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Đến lúc"
                  outlined
                  readonly
                  v-model="endTime"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="isLoading"
                ></v-text-field>
              </template>
              <v-time-picker
                full-width
                format="24hr"
                v-model="endTime"
                @click:minute="$refs.endTimeMenu.save(endTime)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-select
          outlined
          label="Tần suất lặp lại"
          item-text="text"
          item-value="value"
          :items="repeatSelectItems"
          v-model="excludeType"
          :disabled="isLoading"
        ></v-select>
        <div v-if="excludeType == 'month'" class="mb-3">
          <p class="mb-2">Ngoại trừ</p>
          <v-chip
            v-for="index in 12"
            :key="`repeat-value-${index}`"
            class="mr-2 mb-2"
            :color="includes(excludeValue, index) ? 'primary' : ''"
            :disabled="isLoading"
            @click="() => addOrRemoveExcludeValue(index)"
          >
            Tháng {{ index }}
          </v-chip>
        </div>
        <v-menu
          v-if="!isEmpty(excludeType)"
          ref="untilMenu"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :close-on-content-click="false"
          v-model="menuUntil"
          :disabled="isLoading"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Cho đến ngày"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              v-model="until"
              :disabled="isLoading"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="until"
            @input="menuUntil = false"
          ></v-date-picker>
        </v-menu>
        <v-textarea
          outlined
          label="Mô tả sự kiện"
          v-model="description"
          :disabled="isLoading"
        ></v-textarea>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn block text @click="expand = !expand">
        {{ expand ? "Thu gọn thông tin" : "Chi tiết thông tin" }}
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="isOpenUpdateDialog" width="400">
      <v-card>
        <v-card-title>Hình thức cập nhật</v-card-title>
        <v-card-text>
          <v-form ref="formUpdateType" v-model="valid" lazy-validation>
            <v-radio-group
              v-model="updateType"
              :rules="rules.updateType"
              :disabled="isLoading"
            >
              <v-radio
                v-for="({ value, text }, index) in updateTypeSelectItems"
                :key="`radio-update-type-${index}`"
                :label="text"
                :value="value"
              ></v-radio>
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="update" :loading="isLoading">
            Cập nhật
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _get from "lodash/get";
import _includes from "lodash/includes";
import _isEmpty from "lodash/isEmpty";
import _split from "lodash/split";
import _map from "lodash/map";

import moment from "moment";

export default {
  props: {
    isEdit: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    isOpenUpdateDialog: false,
    expand: true,
    eventStatus: {
      draft: "Đang soạn",
      publish: "Hoạt động",
    },
    valid: false,
    menuStartDate: false,
    menuEndDate: false,
    menuUntil: false,
    updateTypeSelectItems: [
      {
        value: "one",
        text: "Chỉ sự kiện này",
      },
      {
        value: "next",
        text: "Sự kiện này và các sự kiện sau",
      },
    ],
    statusSelectItems: [
      {
        value: "draft",
        text: "Đang soạn",
      },
      {
        value: "publish",
        text: "Hoạt động",
      },
    ],
    repeatSelectItems: [
      {
        value: null,
        text: "Không lặp lại",
      },
      {
        value: "day",
        text: "Mỗi ngày",
      },
      {
        value: "week",
        text: "Mỗi tuần",
      },
      {
        value: "month",
        text: "Mỗi tháng",
      },
      {
        value: "year",
        text: "Mỗi năm",
      },
    ],
    name: null,
    description: null,
    status: "draft",
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    excludeType: null,
    excludeValue: [],
    until: null,
    updateType: null,
  }),
  computed: {
    ...mapState("event", { event: (state) => state.event }),
    rules() {
      return {
        name: [(value) => !!value || "Tên sự kiện là bắt buộc"],
        status: [(value) => !!value || "Trạng thái là bắt buộc"],
        startDate: [
          (value) => !!value || "Ngày bắt đầu là bắt buộc",
          (value) =>
            moment(value, "YYYY-MM-DD 23:59:59").isSameOrAfter(
              moment(),
              "day"
            ) || "Ngày bắt đầu là ngày hiện tại hoặc sau đó",
        ],
        startTime: [(value) => !!value || "Giờ bắt đầu là bắt buộc"],
        updateType: [(value) => !!value || "Hình thức cập nhật là bắt buộc"],
      };
    },
  },
  methods: {
    ...mapActions("event", {
      createEvent: "create",
      updateEvent: "update",
    }),
    get(item, ref) {
      return _get(item, ref);
    },
    includes(array, value) {
      return _includes(array, value);
    },
    isEmpty(item) {
      return _isEmpty(item);
    },
    addOrRemoveExcludeValue(value) {
      let isExist = _includes(this.excludeValue, value);
      if (isExist) {
        this.excludeValue = this.excludeValue.filter((item) => item != value);
      } else {
        this.excludeValue = [...this.excludeValue, value];
      }
    },
    genEvent() {
      let event = {
        name: this.name,
        status: this.status,
        startDate: this.startDate,
        startTime: this.startTime,
        repeat: {},
      };

      if (_get(this.event, "id") > 0) {
        event["id"] = _get(this.event, "id");
      }

      if (!_isEmpty(this.endDate)) {
        event["endDate"] = this.endDate;
      }

      if (!_isEmpty(this.endTime)) {
        event["endTime"] = this.endTime;
      }

      if (!_isEmpty(this.description)) {
        event["description"] = this.description;
      }

      if (!_isEmpty(this.excludeValue)) {
        event["excludeValue"] = this.excludeValue.toString();
      }

      if (!_isEmpty(this.excludeType)) {
        event["excludeType"] = this.excludeType;
        event["repeat"][this.excludeType] = 1;
      }

      if (!_isEmpty(this.until)) {
        event["repeat"]["until"] = this.until;
      }

      if (!_isEmpty(this.updateType)) {
        event["updateType"] = this.updateType;
      }

      return event;
    },
    async create() {
      let isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      this.isLoading = true;

      let event = this.genEvent();
      await this.createEvent(event);

      this.isLoading = false;
    },
    async update() {
      let isFormValid = this.$refs.form.validate();
      if (!isFormValid) {
        return;
      }

      let isFormUpdateTypeValid = this.$refs.formUpdateType.validate();
      if (!isFormUpdateTypeValid) {
        return;
      }

      this.isLoading = true;

      let event = this.genEvent();
      await this.updateEvent(event);

      this.isLoading = false;
      this.isOpenUpdateDialog = false;
    },
  },
  watch: {
    event(value) {
      if (_isEmpty(value)) {
        this.name = null;
        this.description = null;
        this.status = "draft";
        this.startDate = null;
        this.startTime = null;
        this.endDate = null;
        this.endTime = null;
        this.excludeType = null;
        this.excludeValue = [];
        this.until = null;
        this.updateType = null;
      }
    },
  },
  mounted() {
    if (!_isEmpty(this.event)) {
      this.name = _get(this.event, "name");
      this.description = _get(this.event, "description");
      this.status = _get(this.event, "status") ?? "draft";
      this.startDate = _get(this.event, "startDate");
      this.startTime = _get(this.event, "startTime");
      this.endDate = _get(this.event, "endDate");
      this.endTime = _get(this.event, "endTime");
      this.excludeType = _get(this.event, "excludeType");
      this.until = _get(this.event, "repeat.until");

      let excludeValue = _get(this.event, "excludeValue");
      excludeValue = excludeValue ? _split(excludeValue, ",") : [];
      excludeValue = _map(excludeValue, (item) => parseInt(item, 10));
      this.excludeValue = excludeValue;
    }
  },
};
</script>
