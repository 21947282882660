<template>
  <v-card>
    <v-row>
      <v-col cols="1">
        <v-btn fab outlined color="primary" class="ml-4 mt-4">
          <v-icon size="32">
            {{ isEmpty(provider) ? "mdi-timeline-text" : get(icon, provider) }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="9">
        <v-card-title class="font-weight-bold">
          {{ title || "Tiêu đề tác vụ" }}
        </v-card-title>
        <v-card-subtitle>
          {{
            get(actionDataType, dataType) || "Hợp đồng kế toán đang thực hiện"
          }}
        </v-card-subtitle>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn
          fab
          small
          outlined
          class="mt-4 mr-2"
          color="error"
          elevation="0"
          :disabled="isLoadingUploadFile || isLoadingUpdate"
          @click="openRemoveDialog"
        >
          <v-icon> mdi-trash-can </v-icon>
        </v-btn>
        <v-btn
          fab
          small
          class="mt-4 mr-4"
          color="primary"
          elevation="0"
          :loading="isLoadingUploadFile || isLoadingUpdate"
          @click="save"
        >
          <v-icon> mdi-content-save </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text v-if="expand">
      <v-form ref="form">
        <v-select
          outlined
          label="Phương thức thực hiện"
          item-text="text"
          item-value="value"
          v-model="provider"
          :disabled="isLoadingUpdate"
          :items="providerSelectItems"
          :rules="rules.provider"
        ></v-select>
        <v-row v-if="provider == 'email'">
          <v-col cols="6" class="py-0 pb-3">
            <v-autocomplete
              chips
              label="Cc mail"
              multiple
              outlined
              single-line
              v-model="cc"
              :disabled="isLoadingUpdate"
              :items="get(configs, 'email')"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" class="py-0 pb-3">
            <v-autocomplete
              chips
              label="Bcc Mail"
              multiple
              outlined
              single-line
              v-model="bcc"
              :disabled="isLoadingUpdate"
              :items="get(configs, 'email')"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-text-field
          v-if="!isEmpty(provider)"
          outlined
          label="Tiêu đề"
          v-model="title"
          :disabled="isLoadingUpdate"
          :rules="rules.title"
        ></v-text-field>
        <v-textarea
          v-if="!isEmpty(provider)"
          outlined
          label="Nội dung"
          rows="15"
          v-model="content"
          :disabled="isLoadingUpdate"
          :rules="rules.content"
        ></v-textarea>
        <v-textarea
          v-if="!isEmpty(provider)"
          outlined
          label="Mô tả ngắn gọn"
          rows="5"
          v-model="description"
          :disabled="isLoadingUpdate"
          :rules="rules.description"
        ></v-textarea>
        <v-row v-if="!isEmpty(provider)">
          <v-col :cols="dataType == 'file' ? 6 : 12">
            <v-select
              outlined
              label="Nguồn dữ liệu"
              item-text="text"
              item-value="value"
              v-model="dataType"
              :disabled="isLoadingUpdate"
              :items="dataTypeSelectItems"
              :rules="rules.dataType"
            ></v-select>
          </v-col>
          <v-col v-if="dataType == 'file'" cols="6">
            <v-file-input
              v-if="dataType == 'file'"
              label="File dữ liệu"
              outlined
              hint="Cột đầu tiên của file sẽ được dùng để làm địa chỉ đích"
              prepend-icon="mdi-file-excel"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              persistent-hint
              truncate-length="50"
              v-model="file"
              :loading="isLoadingUploadFile"
              :disabled="isLoadingUploadFile || isLoadingUpdate"
              :rules="rules.file"
              @change="upload"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn block text @click="expand = !expand">
        {{ expand ? "Thu gọn thông tin" : "Chi tiết thông tin" }}
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-if="isEdit"
      persistent
      width="500"
      v-model="isOpenDeleteConfirm"
    >
      <v-card>
        <v-card-title class="error white--text"> Xoá tác vụ </v-card-title>
        <v-card-text class="pt-4">
          Bạn đang xoá tác vụ gửi
          <b>{{ get(action, "provider") }}</b> với tiêu đề
          <b>{{ get(action, "title") }}</b>
        </v-card-text>
        <v-card-text class="pb-1">
          Sau khi xoá, bạn <b>không thể</b> khôi phục lại tác vụ này.
        </v-card-text>
        <v-card-text> Bạn có chắc chắn xoá không? </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="error"
            :loading="isLoadingDelete"
            @click="remove"
          >
            Xoá
          </v-btn>
          <v-btn
            color="error"
            :disabled="isLoadingDelete"
            @click="isOpenDeleteConfirm = false"
          >
            Không
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

export default {
  props: {
    eventId: {
      type: [String, Number],
      default: 0,
    },
    action: {
      type: Object,
    },
  },
  data: () => ({
    isOpenDeleteConfirm: false,
    expand: false,
    isLoadingUpdate: false,
    isLoadingDelete: false,
    isLoadingUploadFile: false,
    icon: {
      email: "mdi-email",
      sms: "mdi-android-messages",
    },
    actionDataType: {
      erp: "Hợp đồng kế toán đang thực hiện",
      file: "File dữ liệu",
    },
    providerSelectItems: [
      {
        value: "email",
        text: "Email",
      },
      {
        value: "sms",
        text: "Tin nhắn",
      },
    ],
    dataTypeSelectItems: [
      {
        value: "erpAccounting",
        text: "Khách hàng có hợp đồng kế toán đang thực hiện",
      },
      {
        value: "erpAll",
        text: "Tất cả khách hàng có hợp đồng đang thực hiện",
      },
      {
        value: "file",
        text: "File dữ liệu",
      },
    ],
    provider: null,
    title: null,
    description: null,
    content: null,
    dataType: "erpAccounting",
    cc: [],
    bcc: [],
    file: null,
  }),
  computed: {
    ...mapState("action", {
      configs: (state) => state.configs,
      fileUploaded: (state) => state.fileUploaded,
    }),
    isEdit() {
      let actionId = _get(this.action, "id");
      return actionId > 0;
    },
    rules() {
      return {
        provider: [(value) => !!value || "Phương thức thực hiện là bắt buộc"],
        title: [(value) => !!value || "Tiêu đề là bắt buộc"],
        content: [(value) => !!value || "Nội dung là bắt buộc"],
        description: [(value) => !!value || "Mô tả ngắn gọn là bắt buộc"],
        dataType: [(value) => !!value || "Nguồn dữ liệu là bắt buộc"],
        file: [(value) => !!value || "File dữ liệu là bắt buộc"],
      };
    },
  },
  methods: {
    ...mapActions("action", {
      createAction: "create",
      deleteAction: "delete",
      updateAction: "update",
      getConfig: "getConfig",
      uploadFile: "upload",
      resetFileUploaded: "resetFileUploaded",
    }),
    get(item, ref) {
      return _get(item, ref);
    },
    isEmpty(item) {
      return _isEmpty(item);
    },
    async remove() {
      this.isLoadingDelete = true;

      let actionId = _get(this.action, "id");
      let status = await this.deleteAction(actionId);

      this.isLoadingDelete = false;

      if (status) {
        this.$emit("remove");
        this.isOpenDeleteConfirm = false;
      }
    },
    openRemoveDialog() {
      if (this.isEdit) {
        this.isOpenDeleteConfirm = true;
      } else {
        this.$emit("remove");
      }
    },
    genAction() {
      let action = {
        eventId: this.eventId,
        title: this.title,
        content: this.content,
        description: this.description,
        provider: this.provider,
        dataType: this.dataType,
        cc: this.cc,
        bcc: this.bcc,
        reference: {},
      };

      if (_get(this.action, "id") > 0) {
        action["id"] = _get(this.action, "id");
      }

      if (this.dataType == "erp") {
        action["reference"]["target"] = "accounting";
        action["reference"]["conditionActive"] = "publish";
      }

      if (this.dataType == "file") {
        action["fileId"] =
          _get(this.fileUploaded, "id") || _get(this.action, "file.id");
      }

      return action;
    },
    cancel() {
      this.isOpenDeleteConfirm = false;
    },
    async save() {
      let isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      this.isLoadingUpdate = true;

      let action = this.genAction();

      let actionId = _get(this.action, "id");
      if (actionId > 0) {
        await this.updateAction(action);
      } else {
        await this.createAction(action);
      }

      this.isLoadingUpdate = false;
    },
    async upload() {
      if (this.file == null) {
        return false;
      }

      this.isLoadingUploadFile = true;

      let formData = new FormData();
      formData.append("file", this.file);

      await this.uploadFile(formData);

      this.isLoadingUploadFile = false;
    },
  },
  async mounted() {
    this.isLoadingUpdate = true;

    await this.getConfig();

    if (!_isEmpty(this.action)) {
      this.provider = _get(this.action, "provider");
      this.title = _get(this.action, "title");
      this.content = _get(this.action, "content");
      this.description = _get(this.action, "description");
      this.dataType = _get(this.action, "dataType");

      this.cc = _get(this.action, "cc") || [];
      this.bcc = _get(this.action, "bcc") || [];

      this.file = new File([], _get(this.action, "file.name"));
    }

    this.isLoadingUpdate = false;
  },
  destroyed() {
    this.resetFileUploaded();
  },
};
</script>
