<template>
  <v-dialog
    v-model="isOpen"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card color="grey lighten-3" :isLoading="true">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Chi tiết sự kiện</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain elevation="0" @click="isOpenDeleteDialog = true">
          Xoá sự kiện
        </v-btn>
      </v-toolbar>
      <v-container
        v-if="isLoading"
        class="d-flex flex-column justify-center align-center"
      >
        <v-skeleton-loader
          max-width="60rem"
          width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-container>
      <v-container
        v-else
        class="d-flex flex-column justify-center align-center"
      >
        <EventDetail
          class="mt-4"
          style="max-width: 60rem; width: 100%"
          :isEdit="true"
        ></EventDetail>
        <div v-if="!isEmpty(event)" class="step-line"></div>
        <v-tooltip v-if="!isEmpty(event)" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              v-bind="attrs"
              v-on="on"
              @click="() => addAction()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Thêm hành động</span>
        </v-tooltip>
        <div
          v-for="(action, index) in actions"
          :key="`action-${index}`"
          class="d-flex flex-column justify-center align-center"
          style="max-width: 60rem; width: 100%"
        >
          <div class="step-line"></div>
          <div class="step-line-end">▼</div>

          <ActionDetail
            style="max-width: 60rem; width: 100%"
            :eventId="get(event, 'id')"
            :action="action"
            @remove="() => removeAction(index)"
          ></ActionDetail>

          <div class="step-line"></div>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="() => addAction()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Thêm hành động</span>
          </v-tooltip>
        </div>
      </v-container>
    </v-card>
    <v-dialog persistent width="400" v-model="isOpenDeleteDialog">
      <v-card>
        <v-card-title>Hình thức xoá</v-card-title>
        <v-card-text>
          <v-form ref="formDeleteType" v-model="valid" lazy-validation>
            <v-radio-group
              v-model="deleteType"
              :rules="rules.deleteType"
              :disabled="isLoading"
            >
              <v-radio
                v-for="({ value, text }, index) in deleteTypeSelectItems"
                :key="`radio-delete-type-${index}`"
                :label="text"
                :value="value"
              ></v-radio>
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="error"
            :loading="isDeleting"
            @click="removeEvent"
          >
            Xoá
          </v-btn>
          <v-btn
            color="primary"
            :loading="isDeleting"
            @click="isOpenDeleteDialog = false"
          >
            Huỷ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<style lang="scss" scoped>
.step-line {
  background-color: rgb(153, 153, 153);
  height: 16px;
  width: 2px;
  z-index: 0;
}
.step-line-end {
  color: rgb(153, 153, 153);
  z-index: 4;
  line-height: 1;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import EventDetail from "./EventDetail.vue";
import ActionDetail from "./ActionDetail.vue";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    eventId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    EventDetail,
    ActionDetail,
  },
  data: () => ({
    isOpen: false,
    isOpenDeleteDialog: false,
    isLoading: true,
    isDeleting: false,
    eventExpand: false,
    actions: [],
    deleteTypeSelectItems: [
      {
        value: "one",
        text: "Chỉ sự kiện này",
      },
      {
        value: "next",
        text: "Sự kiện này và các sự kiện sau",
      },
    ],
    valid: false,
    deleteType: null,
  }),
  computed: {
    ...mapState("event", {
      event: (state) => state.event,
    }),
    ...mapState("action", {
      actionsInEvent: (state) => state.actionsInEvent,
    }),
    rules() {
      return {
        deleteType: [(value) => !!value || "Hình thức xoá là bắt buộc"],
      };
    },
  },
  watch: {
    value(value) {
      this.isOpen = value;
    },
    async isOpen(value) {
      this.$emit("input", value);

      if (!value) {
        this.resetEvent();

        return;
      }

      if (this.eventId > 0) {
        this.isLoading = true;

        await this.getEventDetail(this.eventId);
        await this.getActionByEventId(this.eventId);

        if (!_isEmpty(this.actionsInEvent)) {
          this.actions = JSON.parse(JSON.stringify(this.actionsInEvent));
        }

        this.isLoading = false;
      }
    },
  },
  methods: {
    ...mapActions("event", {
      resetEvent: "resetEvent",
      getEventDetail: "getById",
      deleteEvent: "delete",
    }),
    ...mapActions("action", {
      getActionByEventId: "getByEventId",
    }),
    get(item, ref) {
      return _get(item, ref);
    },
    isEmpty(item) {
      return _isEmpty(item);
    },
    addAction() {
      this.actions = [...this.actions, {}];
    },
    async removeEvent() {
      let isFormDeleteTypeValid = this.$refs.formDeleteType.validate();
      if (!isFormDeleteTypeValid) {
        return;
      }

      this.isDeleting = true;

      await this.deleteEvent({ id: this.eventId, deleteType: this.deleteType });

      this.isDeleting = false;
      this.isOpenDeleteDialog = false;
      this.isOpen = false;
    },
    removeAction(index) {
      this.actions.splice(index, 1);
    },
  },
  mounted() {
    this.isOpen = this.value;
  },
};
</script>
