var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-3","isLoading":true}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Chi tiết sự kiện")]),_c('v-spacer'),_c('v-btn',{attrs:{"plain":"","elevation":"0"},on:{"click":function($event){_vm.isOpenDeleteDialog = true}}},[_vm._v(" Xoá sự kiện ")])],1),(_vm.isLoading)?_c('v-container',{staticClass:"d-flex flex-column justify-center align-center"},[_c('v-skeleton-loader',{attrs:{"max-width":"60rem","width":"100%","type":"card"}})],1):_c('v-container',{staticClass:"d-flex flex-column justify-center align-center"},[_c('EventDetail',{staticClass:"mt-4",staticStyle:{"max-width":"60rem","width":"100%"},attrs:{"isEdit":true}}),(!_vm.isEmpty(_vm.event))?_c('div',{staticClass:"step-line"}):_vm._e(),(!_vm.isEmpty(_vm.event))?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function () { return _vm.addAction(); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3385356705)},[_c('span',[_vm._v("Thêm hành động")])]):_vm._e(),_vm._l((_vm.actions),function(action,index){return _c('div',{key:("action-" + index),staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"max-width":"60rem","width":"100%"}},[_c('div',{staticClass:"step-line"}),_c('div',{staticClass:"step-line-end"},[_vm._v("▼")]),_c('ActionDetail',{staticStyle:{"max-width":"60rem","width":"100%"},attrs:{"eventId":_vm.get(_vm.event, 'id'),"action":action},on:{"remove":function () { return _vm.removeAction(index); }}}),_c('div',{staticClass:"step-line"}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function () { return _vm.addAction(); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Thêm hành động")])])],1)})],2)],1),_c('v-dialog',{attrs:{"persistent":"","width":"400"},model:{value:(_vm.isOpenDeleteDialog),callback:function ($$v) {_vm.isOpenDeleteDialog=$$v},expression:"isOpenDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Hình thức xoá")]),_c('v-card-text',[_c('v-form',{ref:"formDeleteType",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-radio-group',{attrs:{"rules":_vm.rules.deleteType,"disabled":_vm.isLoading},model:{value:(_vm.deleteType),callback:function ($$v) {_vm.deleteType=$$v},expression:"deleteType"}},_vm._l((_vm.deleteTypeSelectItems),function(ref,index){
var value = ref.value;
var text = ref.text;
return _c('v-radio',{key:("radio-delete-type-" + index),attrs:{"label":text,"value":value}})}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"error","loading":_vm.isDeleting},on:{"click":_vm.removeEvent}},[_vm._v(" Xoá ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isDeleting},on:{"click":function($event){_vm.isOpenDeleteDialog = false}}},[_vm._v(" Huỷ ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }